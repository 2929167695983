import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import index from '../views/index/index'
import home from '../views/home/home'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        component: index,
        children: [
            {
                path: "/",
                name: "home",
                component: home,
                meta: {
                    title: '培考通',
                    tabBackground: 'rgba(41, 40, 40, 0.2)',
                    tabTextColor: '#FFFFFF',
                    paddingTop: '0'
                }
            },
            {
                path: "/trainingSchool",
                name: "trainingSchool",
                meta: {
                    title: "国家职业",
                },
                component: () => import("@/views/training/trainingSchool"),
            },
            {
                path: "/trainingEnterprise",
                name: "trainingEnterprise",
                meta: {
                    title: "企业补贴",
                },
                component: () => import("@/views/training/trainingEnterprise"),
            },
            {
                path: "/trainingPersonal",
                name: "trainingPersonal",
                meta: {
                    title: "技能提升",
                },
                component: () => import("@/views/training/trainingPersonal"),
            },
            {
                path: "/educationPersonal",
                name: "educationPersonal",
                meta: {
                    title: "学历提升",
                },
                component: () => import("@/views/educationPersonal/educationPersonal"),
            },
            {
                path: "/courseCenter",
                name: "courseCenter",
                meta: {
                    title: "课程中心",
                },
                component: () => import("@/views/courseCenter/courseCenter"),
            },
            {
                path: "/courseDetails",
                name: "courseDetails",
                meta: {
                    title: "课程详情",
                },
                component: () => import("@/views/courseCenter/courseDetails"),
            },
            {
                path: "/industryDynamics",
                name: "industryDynamics",
                meta: {
                    title: "行业动态列表",
                },
                component: () => import("@/views/industryDynamics/industryDynamics"),
            },
        ]
    }
];
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) { //设置回到顶部
        if (savedPosition) {
            return savedPosition
        }
        return {x: 0, y: 0}
      }
});

export default router;
