import Vue from 'vue'
import footer from './footer/footer'
import header from './header/header'
import icon from './icon/icon'

const componentData = {
    'v-footer': footer,
    'v-header': header,
    'v-icon': icon,
}

function component () {
    for (let key in componentData) {
        Vue.component(key, componentData[key])
    }
}

export default component