<template>
    <div class="content">
        <van-swipe class="my-swipe" :autoplay="3000" @change="onChange">
            <van-swipe-item v-for="(item, index) in topSwiperList"
                            :key="index">
                <img @click="topSwiperJump(item.url)" class="topSwiper" :src="item.img" alt="">
            </van-swipe-item>
            <template #indicator>
                <div class="firstSwiper-indicatorBox">
                    <div :class="['indicator-item', item === current+1 ? 'indicator-active' : '']"
                         v-for="item in topSwiperList.length" :key="item"></div>
                </div>
            </template>
        </van-swipe>
        <div class="box secondBox">
            <div class="title">国家职业资格培训</div>
            <div class="title-en">National vocational qualification</div>
            <div class="title-description">国家职业资格考试体系是国内最权威的职业技能鉴定考试。我们以丰富的行业人才教育培养经验，与各地的人社局合作，提供国家职业资格考试相关的线上课程，助力各行各业的人才培养和行业能力建设。</div>
            <div class="second-swiper">
                <div class="autoMoveBox">
                    <vue-seamless-scroll
                        :data="autoMoveList"
                        class="seamless-warp"
                        :class-option="classOption"
                    >
                        <div class="autoMoveBox-con">
                            <div class="secondImgBox"
                                 v-for="(item, index) in autoMoveList"
                                 :key="index"
                                 @mouseenter="setZIndex"
                                 @mouseleave="removeZIndex">
                                <div class="secondImgBox-item">
                                    <img class="secondImgBox-img" :src="item.img" alt="">
                                    <div class="secondImgBox-mask">
                                        <span>{{item.title}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vue-seamless-scroll>
                </div>
            </div>
        </div>
        <div class="box firstBox">
            <div class="title">企业补贴定制内训</div>
            <div class="title-en">Enterprise subsidies customized internal training</div>
            <div class="title-description">根据各类行业企业中的职业岗位技术和技能的要求，为企业定制员工内部培训的课程，以及不同技术岗位的人才成长体系，并为企业提供组织考核和考试鉴定等服务，出具相关技术需要的培训证明。同时，还可以跟企业共建企业大学，将员工的培训时长、培训证书与国家或行业协会的培训课程进行互认互转，不断累积学分，有利于以后在国家开放大学体系进行学分置换。</div>
            <div class="first-swiper">
                <el-carousel
                    :autoplay="false"
                    :height=" firstSwiperHeight + 'px'"
                    arrow="never"
                    ref="firstSwiper"
                    indicator-position="none">
                    <el-carousel-item>
                        <div class="swiper-con">
                            <div class="item">
                                <img class="item-img" src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/1a2x.png" alt="">
                                <div class="item-text">酒店</div>
                            </div>
                            <div class="item">
                                <img class="item-img" src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/2a2x.png" alt="">
                                <div class="item-text">餐饮</div>
                            </div>
                            <div class="item">
                                <img class="item-img" src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/3a2x.png" alt="">
                                <div class="item-text">通识课程</div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <!--                <v-icon @click.native="firstSwiperBtn('prev')" class="btn leftBtn" name="icon-arrowBtn" size="44"></v-icon>-->
                <!--                <v-icon @click.native="firstSwiperBtn('next')" class="btn rightBtn" name="icon-arrowBtn" size="44"></v-icon>-->
            </div>
        </div>
        <div class="box">
            <div class="title">职业技能提升课程</div>
            <div class="title-en">Vocational skills improvement courses</div>
            <div class="title-description">除了完善的家政服务、养老护理服务、母婴护理、保育员等完整的课程体系之外，我们正在不断研发和制作各个细分行业认可的职业技能提升课程，并把行业中的重要岗位技能逐步开发成不同等级的职业技能提升课程。</div>
            <div class="third-swiper">
                <div class="swiper-con thirdSwiperCon" :style="{'transform': 'translateX(' + thirdSwiperCon + 'px)'}">
                    <div class="swiperImg swiperQrImg">
                        <img src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/lianchuangbana2x.png" alt="">
                        <div class="wrap" @click="toMiniprogram('lc')">
                            <div class="wrap-title">联创版</div>
                            <div class="wrap-description">专门为合作伙伴定制的个性化管理后台系统。不仅界面简洁易上手，学习成本低，还拥有强大的个性化功能，包括自定义店铺、价格、海报，邀请团队加入、参与合作伙伴排行、后台实时查看订单以及获得公司各种奖励和促销政策的机制。联创版是您开启新事业的财富密码。</div>
                            <div class="wrap-tip">{{qrCodeTip}}进入小程序</div>
                        </div>
                    </div>
                    <div class="swiperImg swiperQrImg">
                        <img src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/kaochangbana2x.png" alt="">
                        <div class="wrap" @click="toMiniprogram('pkt')">
                            <div class="wrap-title">学习版</div>
                            <div class="wrap-description">为每一位热爱终身学习的你而设计。为了让学员们全天使用平台更顺畅便利，学习端的平台开发建设，我们尽可能做到课程内容精彩丰富、课程体系完善专业、课程进阶衔接顺畅、学习界面友好实用。每天实用学习端，帮你成为更优秀的职业精英！</div>
                            <div class="wrap-tip">{{qrCodeTip}}进入小程序</div>
                        </div>
                    </div>
                    <div class="swiperImg swiperQrImg">
                        <img src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/bg-kaochangbana2x.png" alt="">
                        <div class="wrap" @click="toMiniprogram('kc')">
                            <div class="wrap-title">考场版</div>
                            <div class="wrap-description">学员参与模拟考试和成果检测的优秀工具。考场版提供完善的模拟测验功能和真实考试功能，考试通过即可获得电子证书，同时也可以选择纸质证书，让不断累积的技能证书成为通往新职业的敲门砖。</div>
                            <div class="wrap-tip">{{qrCodeTip}}进入小程序</div>
                        </div>
                    </div>
                </div>
                <div class="swiper-bottom">
                    <div class="indicatorBox">
                        <div :class="['indicator-item', thirdSwiperIndex === 1 ? 'indicator-active' : '']"></div>
                        <div :class="['indicator-item', thirdSwiperIndex === 0 ? 'indicator-active' : '']"></div>
                        <div :class="['indicator-item', thirdSwiperIndex === -1 ? 'indicator-active' : '']"></div>
                    </div>
                    <div class="btnBox">
                        <v-icon @click.native="nextImg" class="btn leftBtn" name="icon-arrowBtn" size="36"></v-icon>
                        <v-icon @click.native="prevImg" class="btn rightBtn" name="icon-arrowBtn" size="36"></v-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="box forthBox">
            <div class="title">学历提升合作课程</div>
            <div class="title-en">Studying for new qualitication</div>
            <div class="title-description">我们响应国家教育部的号召，积极推进校企合作和产业共建，与国内数十家中高职院校合作，进行校企合作，共同开发合作专业和创新型的线上线下课程，并将这些新课程提供给更多的学校，尽可能做到证书通用、学分互认等合作方式。</div>
            <div class="forth-swiper">
                <div class="forth-swiper--item forth-swiper--itemOne" @click="toEdu">
                    <div class="forth-swiper--title">中专课程</div>
                    <div class="forth-swiper--desc">公司与国开大中专部等学校建立合作关系，给需要中专学历的学生，提供各类专业的成人教育的中专网校报名通道，完成学习考试即可获得中专学历。</div>
                    <v-icon class="rightBtn" name="icon-arrowBtn" size="36"></v-icon>
                </div>
                <div class="forth-swiper--item forth-swiper--itemTwo" @click="toEdu">
                    <div class="forth-swiper--title">大专课程</div>
                    <div class="forth-swiper--desc">公司与国内十几所大专职业高校开展校企定制和产业学院共建深度合作，为学生提供统招、成人大专的合作院校的报名指导、线上线下学习和考核测评，取得大专学历。</div>
                    <v-icon class="rightBtn" name="icon-arrowBtn" size="36"></v-icon>
                </div>
                <div class="forth-swiper--item forth-swiper--itemThree" @click="toEdu">
                    <div class="forth-swiper--title">本科课程</div>
                    <div class="forth-swiper--desc">公司与上海开放大学完成家政学本科专业的校企定制班，并持续为学员提供本科阶段可选专业，协助学员完成本科学历的报名指导、线上线下学习和考核测评，取得本科学历。</div>
                    <v-icon class="rightBtn" name="icon-arrowBtn" size="36"></v-icon>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="title">合作案例</div>
            <div class="title-en">Cooperation case</div>
            <div class="fifth-swiper">
                <div class="fifthSwiper-itemBox">
                    <div class="fifthSwiper-item" v-for="(item, index) in collegeIcon1" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
                <div class="fifthSwiper-itemBox">
                    <div class="fifthSwiper-item" v-for="(item, index) in collegeIcon2" :key="index">
                        <img :src="item" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
    name: "home",
    components: {
        vueSeamlessScroll
    },
    data () {
        return {
            swiperHeight: '900',
            firstSwiperHeight: '330',
            topSwiperList: [
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/banner1.png',
                    url: ''
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/website/img/banner3.png',
                    url: 'https://mp.weixin.qq.com/s/ptMI2dmShSaROG9dkKVVWQ'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/banner2.png',
                    url: 'https://mp.weixin.qq.com/s/L9VSpiPQ1uhdLA9KE2zo0A'
                }
            ],
            current: 0,
            autoMoveList: [
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove1.png',
                    title: '安全服务类'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove2.png',
                    title: '快递服务类'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove3.png',
                    title: '家政服务类'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove4.png',
                    title: '生活服务类'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove5.png',
                    title: '餐饮服务类'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove6.png',
                    title: '人力资源'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove7.png',
                    title: '计算机'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove8.png',
                    title: '制造类'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove9.png',
                    title: '农业畜牧'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/autoMove10.png',
                    title: '市政类'
                },
            ],
            width: 380,
            speed: 30,
            isScrolling: false,
            thirdSwiperCon: 0,
            thirdSwiperIndex: 1,
            setActiveItem: 0,
            qrCodeTip: '扫码',
            miniUrl: {
                pkt: 'weixin://dl/business/?t=lWDUyodVAyl',
                lc: 'weixin://dl/business/?t=P8edz0Tnptr',
                kc: 'weixin://dl/business/?t=MnB9T45FeVt'
            },
            collegeIcon1: [
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c1a2x-4.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c2a2x-4.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c3a2x-4.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c4a2x-2.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c5a2x-2.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c6a2x-2.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c7a2x-2.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c8a2x-2.png'
            ],
            collegeIcon2: [
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c9a2x-2.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c10a2x-2.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c11a2x.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c12a2x.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c13a2x.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c14a2x.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c15a2x.png',
                'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/c16a2x.png'
            ]
        }
    },
    computed: {
        classOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                // openWatch: true, //  开启数据实时监控刷新dom
                // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            };
        }
    },
    mounted () {
        let fn = this.init
        fn()
        window.onresize = function () {
            fn()
        }
    },
    methods: {
        onChange(index) {
            this.current = index;
        },
        topSwiperJump (url) {
            if (url) {
                window.open(url, 'blank')
            }
        },
        firstSwiperBtn (type) {
            if (type === 'prev') {
                this.$refs.firstSwiper.prev()
            } else {
                this.$refs.firstSwiper.next()
            }
        },
        init () {
            let con = document.querySelector('.indicatorBox'),
                autoMoveCard = document.querySelector('.secondImgBox')
            this.thirdSwiperCon =  con.offsetLeft
            this.thirdSwiperIndex = 1
            this.width = autoMoveCard.clientWidth
            let width = window.innerWidth
            this.swiperHeight = width < 1560 ? 700 : 900
            this.firstSwiperHeight = width < 1560 ? 230 : 330
            this.judgePhone()
        },
        judgePhone () {
            var u = navigator.userAgent
                // app = navigator.appVersion;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
            var isIOS = !!u.match(/(i[^;]+;( U;)? CPU.+Mac OS X)/); //ios终端
            if (isAndroid || isIOS) {
                this.qrCodeTip = '点击'
            } else {
                this.qrCodeTip = '扫码'
            }
        },
        prevImg () {
            if (this.thirdSwiperIndex <= -1) {
                return
            }
            let item = document.querySelector('.swiperQrImg')
            this.thirdSwiperCon -= (item.clientWidth + 40)
            this.thirdSwiperIndex -= 1
        },
        nextImg () {
            if (this.thirdSwiperIndex >= 1) {
                return
            }
            let item = document.querySelector('.swiperQrImg')
            this.thirdSwiperCon += (item.clientWidth + 40)
            this.thirdSwiperIndex += 1
        },
        setZIndex (e) {
            e.target.style.zIndex = '9'
        },
        removeZIndex (e) {
            e.target.style.zIndex = '1'
        },
        toMiniprogram (type) {
            if (this.qrCodeTip === '点击') {
                window.location.href = this.miniUrl[type]
            }
        },
        toEdu () {
            this.$router.push({name: 'educationPersonal'})
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
    .el-carousel {
        .is-active .el-carousel__button {
            background: #F66B53;
            opacity: 1;
        }
        .el-carousel__button {
            background: #FFFFFF;
            opacity: 1;
        }
    }
}
@media screen and (max-width: 1560px){
    .content {
        width: 100%;
        min-width: 1200px;
        min-height: 800px;
        box-sizing: border-box;

        .topSwiper {
            width: 100%;
            height: 100%;
        }

        .my-swipe {

            .firstSwiper-indicatorBox {
                position: absolute;
                bottom: 25px;
                left: 50%;
                transform: translateX(-50%);
                height: 3px;
                display: flex;
                .indicator-item {
                    width: 50px;
                    height: 3px;
                    background: #FFFFFF;
                    margin-left: 9px;
                }
                .indicator-item:first-child {
                    margin-left: 0;
                }
                .indicator-active {
                    background: #F66B53;
                }
            }
        }

        .box {
            width: 100%;
            background: #FFFFFF;
            color: #333333;
            padding-bottom: 60px;

            .title {
                margin-top: 70px;
                font-size: 30px;
                font-weight: 500;
                text-align: center;
            }
            .title-en {
                font-size: 15px;
                margin-top: 6px;
                text-align: center;
            }
            .title-description {
                font-size: 15px;
                font-weight: 400;
                color: #999999;
                margin: 28px auto 0;
                max-width: 800px;
            }
            .first-swiper {
                margin: 60px auto 0;
                width: 960px;
                position: relative;

                .el-carousel {
                    width: 800px;
                    margin: auto;
                }

                .swiper-con {
                    display: flex;
                    justify-content: space-between;

                    .item {

                        .item-img {
                            width: 260px;
                            height: 173px;
                            border-radius: 6px;
                        }
                        .item-text {
                            margin-top: 30px;
                            text-align: center;
                        }
                    }
                }
                .btn {
                    position: absolute;
                    top: 68px;
                    z-index: 99;
                }
                .leftBtn {
                    left: 0;
                }
                .rightBtn {
                    transform: rotateY(180deg);
                    right: 0;
                }
            }

            .second-swiper {
                width: 100%;
                height: 400px;
                padding: 60px 0;
                overflow: hidden;

                .autoMoveBox {
                    width: 100%;
                    height: 400px;
                    //position: relative;

                    .seamless-warp {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }

                    .autoMoveBox-con {
                        width: 2800px;
                    }

                    .secondImgBox {
                        width: 280px;
                        height: 400px;
                        float: left;
                        position: relative;
                        //transition-property: left;
                        //transition-timing-function: linear;
                    }
                    .secondImgBox:hover {
                        z-index: 9;
                    }
                    .secondImgBox-item {
                        width: 280px;
                        height: 400px;
                        transition: all .3s;

                        .secondImgBox-mask {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 280px;
                            height: 80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            color: #FFFFFF;
                            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 47%, rgba(0, 0, 0, 0) 100%);
                            transition: all .3s;
                        }
                    }
                    .secondImgBox-item:hover {
                        transform: scale(1.1);

                        .secondImgBox-mask {
                            width: 280px;
                            height: 400px;
                            background: rgba(0, 0, 0, 0.3);
                        }
                    }

                    .secondImgBox-img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .third-swiper {
                padding: 60px 0 0;
                overflow: hidden;

                .swiper-con {
                    width: 2480px;
                    display: flex;
                    justify-content: space-between;

                    .swiperImg {
                        width: 800px;
                        height: 280px;
                        margin-left: 40px;
                        position: relative;
                        img {
                            width: 800px;
                            height: 280px;
                        }

                        .wrap {
                            width: 800px;
                            height: 280px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            box-sizing: border-box;
                            padding: 30px 46px;
                            color: #FFFFFF;
                            .wrap-title {
                                font-size: 24px;
                                font-weight: 500;
                            }
                            .wrap-description {
                                width: 400px;
                                word-break: break-all;
                                font-size: 13px;
                                margin-top: 6px;
                            }
                            .wrap-tip {
                                position: absolute;
                                bottom: 35px;
                                left: 140px;
                                font-size: 12px;
                                font-weight: 500;
                            }
                        }
                    }
                    .swiperImg:first-child {
                        margin-left: 0;
                    }
                }

                .thirdSwiperCon {
                    transition: all .3s;
                }

                .swiper-bottom {
                    width: 800px;
                    margin: 60px auto 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .indicatorBox {
                    width: 170px;
                    height: 3px;
                    display: flex;
                    justify-content: space-between;
                    .indicator-item {
                        width: 50px;
                        height: 3px;
                        background: #cccccc;
                    }
                    .indicator-active {
                        background: #F66B53;
                    }
                }
                .rightBtn {
                    transform: rotateY(180deg);
                    margin-left: 16px;
                }
            }
            .forth-swiper {
                max-width: 800px;
                margin: 60px auto 0;
                height: 350px;
                display: flex;
                justify-content: space-between;

                .forth-swiper--item {
                    width: 240px;
                    height: 350px;
                    //box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 2px 20px 0px rgba(230, 235, 225, 0.3);
                    border-radius: 12px;
                    filter: blur(0px);
                    position: relative;
                    cursor: pointer;

                    .forth-swiper--title {
                        font-size: 22px;
                        font-weight: 500;
                        color: #3A3E4C;
                        text-align: center;
                        margin-top: 150px;
                    }
                    .forth-swiper--desc {
                        width: 182px;
                        font-size: 13px;
                        color: #999999;
                        margin: 15px auto 0;
                    }
                    .rightBtn {
                        cursor: pointer;
                        transform: rotateY(180deg);
                        position: absolute;
                        right: 15px;
                        bottom: 15px;
                    }
                }
                .forth-swiper--itemOne {
                    background-image: url("https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/bg1a2x.png");
                    background-size: 100% 100%;
                }
                .forth-swiper--itemTwo {
                    background-image: url("https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/bg2a2x.png");
                    background-size: 100% 100%;
                }
                .forth-swiper--itemThree {
                    background-image: url("https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/bg3a2x.png");
                    background-size: 100% 100%;
                }
            }
            .fifth-swiper {
                width: 800px;
                box-sizing: border-box;
                overflow: hidden;
                margin: auto;

                .fifthSwiper-itemBox {
                    margin-top: 60px;
                    display: flex;
                    justify-content: space-between;
                }
                .fifthSwiper-item {
                    width: 79px;
                    height: 79px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .firstBox {
            background-image: url("../../assets/images/home/bg@2x.png");
            background-size: 100% 100%;
            overflow: hidden;
        }

        .secondBox {
            padding-bottom: 0;
        }

        .forthBox {
            background-image: url("../../assets/images/home/bg@2x-2.png");
            background-size: 100% 100%;
            overflow: hidden;
        }
    }
}

@media screen and (min-width: 1560px) {
    .content {
        width: 100%;
        min-width: 1200px;
        min-height: 800px;

        .topSwiper {
            width: 100%;
            height: 100%;
        }

        .my-swipe {

            .firstSwiper-indicatorBox {
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translateX(-50%);
                height: 3px;
                display: flex;
                .indicator-item {
                    width: 60px;
                    height: 3px;
                    background: #FFFFFF;
                    margin-left: 12px;
                }
                .indicator-item:first-child {
                    margin-left: 0;
                }
                .indicator-active {
                    background: #F66B53;
                }
            }
        }

        .box {
            width: 100%;
            background: #FFFFFF;
            color: #333333;
            padding-bottom: 60px;

            .title {
                margin-top: 70px;
                font-size: 36px;
                font-weight: 500;
                text-align: center;
            }

            .title-en {
                font-size: 18px;
                margin-top: 6px;
                text-align: center;
            }

            .title-description {
                font-size: 18px;
                color: #999999;
                margin: 28px auto 0;
                max-width: 1200px;
            }
        }

        .firstBox {
            //padding-bottom: 0;
        }

        .first-swiper {
            margin: 60px auto 0;
            width: 1200px;
            position: relative;

            .el-carousel {
                width: 1200px;
                margin: auto;
            }

            .swiper-con {
                display: flex;
                justify-content: space-between;

                .item {

                    .item-img {
                        width: 380px;
                        height: 253px;
                        border-radius: 6px;
                    }
                    .item-text {
                        margin-top: 30px;
                        text-align: center;
                    }
                }
            }
            .btn {
                position: absolute;
                top: 105px;
                z-index: 99;
            }
            .leftBtn {
                left: 0;
            }
            .rightBtn {
                transform: rotateY(180deg);
                right: 0;
            }
        }

        .second-swiper {
            width: 100%;
            height: 500px;
            padding: 60px 0;
            overflow: hidden;

            .autoMoveBox {
                width: 100%;
                height: 500px;
                //position: relative;

                .seamless-warp {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                }

                .autoMoveBox-con {
                    width: 3800px;
                }

                .secondImgBox {
                    width: 380px;
                    height: 500px;
                    float: left;
                    position: relative;
                    //transition-property: left;
                    //transition-timing-function: linear;
                }
                .secondImgBox:hover {
                    z-index: 9;
                }
                .secondImgBox-item {
                    width: 380px;
                    height: 500px;
                    transition: all .3s;

                    .secondImgBox-mask {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 380px;
                        height: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 24px;
                        color: #FFFFFF;
                        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 47%, rgba(0, 0, 0, 0) 100%);
                        transition: all .3s;
                    }
                }
                .secondImgBox-item:hover {
                    transform: scale(1.1);

                    .secondImgBox-mask {
                        width: 380px;
                        height: 500px;
                        background: rgba(0, 0, 0, 0.3);
                    }
                }

                .secondImgBox-img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .third-swiper {
            padding: 60px 0 0;
            overflow: hidden;

            .swiper-con {
                width: 3680px;
                display: flex;
                justify-content: space-between;

                .swiperImg {
                    width: 1200px;
                    height: 400px;
                    margin-left: 40px;
                    position: relative;

                    img {
                        width: 1200px;
                        height: 400px;
                    }

                    .wrap {
                        width: 1200px;
                        height: 400px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        box-sizing: border-box;
                        padding: 50px 70px;
                        color: #FFFFFF;
                        .wrap-title {
                            font-size: 28px;
                            font-weight: 500;
                        }
                        .wrap-description {
                            width: 576px;
                            word-break: break-all;
                            font-size: 16px;
                            margin-top: 20px;
                        }
                        .wrap-tip {
                            position: absolute;
                            bottom: 50px;
                            left: 212px;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
                .swiperImg:first-child {
                    margin-left: 0;
                }
            }

            .thirdSwiperCon {
                transition: all .3s;
            }

            .swiper-bottom {
                width: 1200px;
                margin: 60px auto 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .indicatorBox {
                width: 200px;
                height: 4px;
                display: flex;
                justify-content: space-between;
                .indicator-item {
                    width: 60px;
                    height: 4px;
                    background: #cccccc;
                }
                .indicator-active {
                    background: #F66B53;
                }
            }
            .rightBtn {
                transform: rotateY(180deg);
                margin-left: 16px;
            }
        }

        .forth-swiper {
            max-width: 1200px;
            margin: 60px auto 0;
            height: 500px;
            display: flex;
            justify-content: space-between;

            .forth-swiper--item {
                width: 350px;
                height: 500px;
                box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                filter: blur(0px);
                position: relative;

                .forth-swiper--title {
                    font-size: 28px;
                    font-weight: 500;
                    color: #3A3E4C;
                    text-align: center;
                    margin-top: 225px;
                }
                .forth-swiper--desc {
                    width: 288px;
                    font-size: 16px;
                    color: #999999;
                    margin: 30px auto 0;
                }
                .rightBtn {
                    cursor: pointer;
                    transform: rotateY(180deg);
                    position: absolute;
                    right: 25px;
                    bottom: 25px;
                }
            }
            .forth-swiper--itemOne {
                background-image: url("https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/bg1a2x.png");
                background-size: 100% 100%;
            }
            .forth-swiper--itemTwo {
                background-image: url("https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/bg2a2x.png");
                background-size: 100% 100%;
            }
            .forth-swiper--itemThree {
                background-image: url("https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/bg3a2x.png");
                background-size: 100% 100%;
            }
        }
        .fifth-swiper {
            width: 1200px;
            box-sizing: border-box;
            overflow: hidden;
            margin: auto;

            .fifthSwiper-itemBox {
                margin-top: 60px;
                display: flex;
                justify-content: space-between;
            }
            .fifthSwiper-item {
                width: 101px;
                height: 101px;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 100px;
                    height: 100px;
                }
            }
        }

        .firstBox {
            background-image: url("../../assets/images/home/bg@2x.png");
            background-size: 100% 100%;
            overflow: hidden;
        }

        .secondBox {
            padding-bottom: 0;
        }

        .forthBox {
            background-image: url("../../assets/images/home/bg@2x-2.png");
            background-size: 100% 100%;
            overflow: hidden;
        }
    }
}
</style>