<template>
    <div class="content">
        <div class="footerBox">
            <div class="footer-item">
                <div class="item-title">首页</div>
                <div class="item-row">国家职业</div>
                <div class="item-row">企业补贴</div>
                <div class="item-row">技能提升</div>
                <div class="item-row">学历提升</div>
<!--                <div class="item-row">关于我们</div>-->
            </div>
            <div class="footer-item footer-item--second">
                <div class="item-title">关于培考通</div>
                <div class="item-row row-textarea">
                    让你随时开启职业技能学习，轻松规划职业发展生涯。 2019年创立，现有300多门自主研发及多方合作的优质线上课程，覆盖家政养老、酒店餐饮、机械制造等课程。 2020年，培考通入选上海市互联网职业技能线上培训平台，并为数十家大型企业提供超过五万人次的内训服务。 2021年底，培考通被中国就业培训技术指导中心推荐成为国家人社部指定的优质线上职业技能培训平台。
                </div>
            </div>
            <div class="footer-item">
                <div class="item-title">联系我们</div>
                <div class="item-row row-spec">
                    <v-icon class="icon" name="icon-phone"></v-icon>
                    <span>客服热线</span>
                </div>
                <div class="item-row item-title row-phone">021-63331033</div>
                <div class="item-row row-tip">(周一至周五：09:00-17:00；周六至周日：09:30-16:00）</div>
                <div class="item-row row-spec">
                    <v-icon class="icon" name="icon-address"></v-icon>
                    <span>公司地址</span>
                </div>
                <div class="item-title row-address">上海市黄浦区鲁班路558号4楼</div>
            </div>
            <div class="footer-item">
                <div class="item-title">公众号</div>
                <div class="item-row">
                    <img class="gzhImg" src="../../assets/img/QC.png" alt="">
<!--                    <img class="gzhImg" src="../../assets/img/QC.png" alt="">-->
                </div>
                <div class="item-title row-link">
                    <span>友情链接：</span>
                    <v-icon @click="jumpToJTGJ" class="icon" name="icon-jgLogo" size="26"></v-icon>
                    <span @click="jumpToJTGJ">家庭管家</span>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            Copyright 2020-2022 家庭管家（上海）科技有限公司      沪ICP备 Copyright© 2019 沪ICP备19033962号-3
        </div>
    </div>
</template>

<script>
export default {
    name: "v-footer",
    data () {
        return {
            url: 'http://www.jtgj365.com/'
        }
    },
    methods: {
        jumpToJTGJ () {
            window.open(this.url, 'blank')
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    min-width: 1200px;
    height: auto;
    padding: 70px 10% 0;
    //background: #293054;
    color: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    position: relative;
    background-image: url("../../assets/images/common/bg@2x-3.png");
    background-size: 100% 100%;

    .footerBox {
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
    }
    .footer-item {
        .item-title {
            font-size: 16px;
            color: #FFFFFF;
            font-weight: 500;
        }
        .item-row {
            margin-top: 20px;
            font-size: 14px;
            word-break: break-all;

            .gzhImg {
                width: 94px;
                height: 94px;
            }
            .gzhImg:nth-child(2) {
                margin-left: 30px;
            }
        }
        .row-textarea {
            margin-top: 14px;
            line-height: 30px;
        }
        .row-spec {
            font-size: 12px;
            span {
                padding-left: 3px;
            }
            .icon {
                position: relative;
                top: -2px;
            }
        }
        .row-phone {
            margin-top: 17px;
            font-size: 20px;
        }
        .row-tip {
            font-size: 10px;
            margin-top: 14px;
            padding-bottom: 10px;
        }
        .row-address {
            margin-top: 17px;
        }
        .row-link {
            margin-top: 60px;
            .icon {
                position: relative;
                top: -2px;
                cursor: pointer;
            }
            span:last-child {
                padding-left: 4px;
                cursor: pointer;
            }
        }
    }
    .footer-item--second {
        width: 308px;
    }

    .footer-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: #383739;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
    }
}
@media screen and (max-width: 1560px) {
    .content {
        padding: 70px 10% 0;
    }
}
@media screen and (min-width: 1560px) {
    .content {
        padding: 70px 16% 0;
    }
}
</style>