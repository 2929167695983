import {
  // get,
  // get2,
  // post2,
  post
} from '../api/http'
const api = {
    submitConsulting: params => post("/jtgj-booking/submite", params),
}
export default api;
