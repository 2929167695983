<template>
<div class="content" :style="{'background': tabBackground}">
    <div class="headerBox" :style="{'color': tabTextColor}">
        <div class="headerBox-left">
            <img class="headerBox-logo" src="../../assets/img/jpg-logo.png" alt="">
        </div>
        <div class="headerBox-right">
            <div class="tabBarBox">
                <div :class="['tabBarBox-item', index === tabIndex ? 'tabBarBox-item--active' : '']"
                     v-for="(item, index) in tabData"
                     :key="index"
                     @click="switchTab(index)">
                    <div class="text">{{ item.text }}</div>
                </div>
            </div>
            <div class="lineBox">
                <div class="line" :style="{'width': lineWidth, 'transform': 'translateX(' + lineLeft + ')'}"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "v-header",
    props: {
        tabTextColor: {
            type: String,
            default: '#333333'
        },
        tabBackground: {
            type: String,
            default: '#FFFFFF'
        }
    },
    data () {
        return {
            tabData: [
                {
                    text: '首页',
                    path:"/"
                },
                {
                    text: '国家职业',
                    path:"/trainingSchool"
                },
                {
                    text: '企业补贴',
                    path:"/trainingEnterprise"
                },
                {
                    text: '技能提升',
                    path:"/trainingPersonal"
                },
                {
                    text: '学历提升',
                    path:"/educationPersonal"
                },
                // {
                //     text: '关于我们',
                //     path:"/"
                // }
            ],
            tabIndex: -1,
            lineWidth: '0px',
            lineLeft: '0px'
        }
    },
    mounted () {
        let fn = this.changeLine
        window.onresize = function () {
            fn()
        }
        this.init()
    },
    beforeUpdate () {
        let fn = this.changeLine
        window.onresize = function () {
            fn()
        }
    },
    watch: {
        $route () {
            this.init()
        }
    },
    methods: {
        switchTab (index) {
            this.tabIndex = index;
            this.$router.push(this.tabData[index])
            this.changeLine()
        },
        changeLine () {
            if (this.tabIndex !== -1) {
                setTimeout(() => {
                    let tabEl = document.getElementsByClassName('tabBarBox-item')
                    this.lineWidth = tabEl[this.tabIndex].clientWidth + 'px'
                    this.lineLeft = tabEl[this.tabIndex].offsetLeft + 'px'
                }, 100)
            }
        },
        init () {
            let path = this.$route.path
            this.tabIndex = -1
            this.tabData.map((item, index) => {
                if (item.path === path) {
                    this.tabIndex = index
                    this.changeLine()
                }
            })
            if (this.tabIndex === -1) {
                this.lineWidth = '0px'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    height: 78px;
    background: rgba(41, 40, 40, 0.2);
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: all .3s;

    div {
        box-sizing: border-box;
    }

    .headerBox {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FFFFFF;

        .headerBox-left {
            width: 44px;
            height: 44px;
            .headerBox-logo {
                width: 100%;
                height: 100%;
            }
        }
        .headerBox-right {
            width: 80%;
            height: 100%;
            //margin-left: 150px;

            .tabBarBox {
                width: 100%;
                height: 76px;
                display: flex;
                justify-content: space-between;

                .tabBarBox-item {
                    cursor: pointer;
                    height: 76px;
                    line-height: 76px;

                    .text-active {
                        border-bottom: 2px solid #FFFFFF;
                    }
                }
                .tabBarBox-item--active {
                    color: #EA5A41;
                }
                .tabBarBox-item:hover {
                    color: #EA5A41;
                }
            }
            .lineBox {
                width: 100%;
                height: 2px;
                .line {
                    height: 2px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: #EA5A41;
                    transition: all .3s;
                }
            }
        }
    }
}
@media screen and (max-width: 1560px) {
    .content {
        padding: 0 10%;
    }
}
@media screen and (min-width: 1560px) {
    .content {
        padding: 0 16%;
    }
}
</style>