<template>
    <div class="icon-content">
        <img :style="style" :src="iconName" alt="imgLoadFailed">
    </div>
</template>

<script>
    import arrow from '../../assets/images/icons/icon-arrow@2x.png'
    import phone from '../../assets/images/icons/icon-phone@2x.png'
    import address from '../../assets/images/icons/icon-adress@2x.png'
    import jtgjLogo from '../../assets/images/icons/logo@2x.png'
    import pencialBook from '../../assets/images/icons/icon-pencialBook@2x.png'
    import arrowR from '../../assets/images/icons/arrow-right.png'
    import arrowRA from '../../assets/images/icons/arrow-right-active.png'
    export default {
        props: {
            size: {
                type: [Number, String],
                default: 16
            },
            name: {
                type: String,
                default: 'icon-arrowBtn'
            }
        },
        name: "icon",
        data () {
            return {
                iconData: {
                    'icon-arrowBtn': arrow,
                    'icon-phone': phone,
                    'icon-address': address,
                    'icon-jgLogo': jtgjLogo,
                    'icon-penBook': pencialBook,
                    'icon-arrowR': arrowR,
                    'icon-arrowRA': arrowRA,
                }
            }
        },
        computed: {
            style () {
                return `width: ${this.size}px; height: ${this.size}px`
            },
            iconName () {
                return this.iconData[this.name]
            }
        }
    }
</script>

<style lang="scss" scoped>
.icon-content {
    display: inline-block;
    vertical-align: middle;
}
</style>