<template>
<div class="content">
    <v-header :tab-background="tabBackground" :tab-text-color="tabTextColor"></v-header>
    <div class="main" :style="{'padding-top': mainPaddingTop+'px'}">
        <router-view></router-view>
    </div>
    <v-footer></v-footer>
</div>
</template>

<script>
export default {
    name: "index",
    data () {
        return {
            tabBackground: '#FFFFFF',
            tabTextColor: '#333333',
            mainPaddingTop: '0',
            swiperHeight: 0
        }
    },
    created () {
        this.init()
        this.addScroll();
    },
    watch: {
        $route () {
            document.documentElement.scrollTop = 0
            this.init()
        }
    },

    methods:{
        init () {
            let meta = this.$route.meta
            this.tabBackground = meta.tabBackground || '#FFFFFF'
            this.tabTextColor = meta.tabTextColor || '#333333'
            this.mainPaddingTop = meta.paddingTop || '78'
            let width = window.innerWidth
            this.swiperHeight = width < 1560 ? 620 : 820
        },
        addScroll () {
            let _this = this
            window.onscroll = function () {
                let s = document.documentElement.scrollTop
                if (_this.$route.path === '/') {
                    if (s < _this.swiperHeight) {
                        _this.tabBackground = _this.$route.meta.tabBackground
                        _this.tabTextColor = _this.$route.meta.tabTextColor
                    } else {
                        // console.log(_this.tabBackground)
                        _this.tabBackground = '#FFFFFF'
                        _this.tabTextColor = '#333333'
                    }
                }
            }
        }

    }
}
</script>

<style lang="scss" scoped>
    .content {
        width: 100%;

        .main {
            min-height: calc(100vh - 366px);
            min-width: 1200px;
        }
    }
</style>