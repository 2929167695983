import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/styles/global.css";
import Vant from "vant";
import "vant/lib/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import component from './components/components'
Vue.use(component)
Vue.use(ElementUI);
import api from '@/assets/api/api';
Vue.prototype.$api = api;
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
