/*
 * Http访问接口封装
 */

'use strict'
import { Message } from 'element-ui'
import axios from 'axios'
import Router from "../../router/index";
// import $ from 'jquery';
/**
 * axios 设置默认值
 * @type {string}
 */
let baseURL ='';
baseURL = 'http://121.41.105.115:7234' 
// baseURL = 'http://192.168.1.118:7234' 

export {baseURL};
axios.defaults.baseURL = baseURL;
axios.defaults.timeout = 30000; // 30秒超时
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.responseType = 'json';

// 在实例已创建后修改默认值
axios.defaults.headers.common['Authorization'] = "";

/*
 * 请求拦截
 * 通过request 拦截器，可以需要的参数传到服务端
 * 示例为获取Cookie中存储的session传输到后台
 */
axios.interceptors.request.use(
    config => {
        // 设置请求头token
        // let sessionId = localStorage.getItem('sessionId')

        // if (sessionId) {
        //    config.headers['token'] = sessionId;
        // }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

/*
 * 响应拦截
 */
axios.interceptors.response.use(
    response => {
      if (response) {
          if (response.status === 200) {
              if(response.data){
                  if(response.data.status==2){      //登录失效
                    //   localStorage.removeItem('sessionId')
                    localStorage.clear();
                    Router.push('/login');
                  }else if(response.data.status==3){      //其他设备登录
                      localStorage.clear();
                      Router.push('/login');
                  }else{
                      // localStorage.setItem('sessionId',response.data.token)
                      // console.log(response.data.token,'=======')
                  }
              }
              return Promise.resolve(response)
          } else {
              return Promise.reject(response)
          }
      }
      return response
    },
    error => {
        if(error&&error.response){
            switch (error.response.status) {
                case 403:
                    error.message = '拒绝访问(403)'; break
                case 404:
                    error.message = '请求出错(404)'; break
                case 500:
                    error.message = '服务器错误(500)'; break
                case 501:
                    error.message = '服务未实现(501)'; break
                case 502:
                    error.message = '网络错误(502)'; break
                case 503:
                    error.message = '服务不可用(503)'; break
                case 504:
                    error.message = '网络超时(504)'; break
                case 505:
                    error.message = 'HTTP版本不受支持(505)'; break
                default:
                    error.message = `连接出错(${error.response.status})!`
            }
        }
        Message.error(error.message)
        // return Promise.reject(err)
    }
)

/**
 * get请求
 * @param {String} url 请求地址
 * @param {Object} params 请求参数
 * @returns {Promise}
 */
export function get(url, params) {
  
    url = url + '?v=' + Math.random()

    axios.defaults.headers.token = '';

    return new Promise((resolve, reject) => {
        axios.get(url, { params: params }).then((res) => {
            resolve(res.data)
            if(!res.data.success){
                if(res.data.msg){
                    return Message.error(res.data.msg);
                }else{
                    return Message.error('系统异常~')
                }
            }
        }).catch((error) => {
            reject(error.data)
            if(error.data){
                return Message.error('系统异常~')
            }
            
        })
    })
}
export function get2(url, params) {
    
    url = url + '?v=' + Math.random()

    let sessionId = localStorage.getItem('sessionId')
    if (sessionId) {
        axios.defaults.headers.token = sessionId;
        // axios.defaults.headers.token = 'AD3063F422839C91E1101CC607B7D384';
    }
    // axios.defaults.headers.token = 'AD3063F422839C91E1101CC607B7D384';
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then((res) => {
            resolve(res.data)
            
            if(!res.data.success){
                if(res.data.msg){
                    return Message.error(res.data.msg);
                }else{
                    return Message.error('系统异常~')
                }
            }
        }).catch((error) => {
            
            reject(error.data)
            if(error.data){
                return Message.error('系统异常~')
            }
            
        })
    })
}
/*
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} data [请求时携带的body数据]
 */
export function post(url, data) {
    axios.defaults.headers.token = '';
    return new Promise((resolve, reject) => {

        axios.post(url, data).then((res) => {
            resolve(res.data)
            if(!res.data.success){
                if(res.data.msg){
                    return Message.error(res.data.msg);
                }else{
                    return Message.error('系统异常~')
                }
            }
        }).catch((error) => {
            reject(error.data)
            if(error.data){
                return Message.error('系统异常~')
            }
            
        })
    })
}


export function post2(url, data) {
    url = url + '?v=' + Math.random()
    let sessionId = localStorage.getItem('sessionId')
    if (sessionId) {
        axios.defaults.headers.token = sessionId;
    }
    return new Promise((resolve, reject) => {
        axios.post(
            url,
            data,
        ).then((res) => {
            resolve(res.data)
            
            if(!res.data.success){
                if(res.data.msg){
                    return Message.error(res.data.msg);
                }else{
                    return Message.error('系统异常~')
                }
            }
        }).catch((error) => {
            
            reject(error.data)
            if(error.data){
                return Message.error('系统异常~')
            }
            
        })
    })
}
